<template>
  <div class="w-full h-screen flex justify-center items-center px-8 py-20">
    <div
      class="modal__container lg:max-w-[696px] md:max-h-[564px] pb-6 px-4 md:p-10 w-full h-full border rounded-2xl border-white/10 bg-black/80 shadow-lg backdrop-blur-lg"
    >
      <div class="flex flex-col h-full">
        <div class="flex-1 flex flex-col">
          <!-- <h1
            class="container__header py-6 text-center text-title-medium md:text-title-big text-white opacity-[0.87]"
          >
            {{ title }}
          </h1> -->
          <p class="pt-16 text-body-big text-white opacity-[0.87]">
            {{ message }}
          </p>
        </div>
        <div class="flex justify-center items-center">
          <ButtonsCta class="variant-primary" @click="onOkayClick">
            {{ $t("Okay") }}
          </ButtonsCta>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  stats: Object,
  emitOkayClick: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits("on-okay-click");
const { t } = useI18n();

const title = computed(() => {
  switch (props.stats.status) {
    case 40007:
      break;

    default:
      return "Oops, something went wrong...";
  }
});

const message = computed(() => {
  switch (props.stats.status) {
    case 1203001:
      return t("watchErrorGeoBlock");

    default:
      return t("watchErrorGeneric");
  }
});

const onOkayClick = () => {
  if (props.emitOkayClick) {
    return emit("on-okay-click");
  }

  useBackEvent();
};
</script>

<style lang="scss" scoped></style>
